.efectoZoom {
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.efectoZoom:hover {
  transform: scale(1.1);
}

.parrafo {
  white-space: pre-line;
}

.boton-redCorporative {
  background: linear-gradient(to bottom, #822525, #c62828) !important;
  border-color: #c62828 !important;
}

.boton-redCorporative:hover {
  transform: scale(1.05);
}

.add-photo-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #c62828;
  position: absolute;
  right: 0;
  top: "50%";
  transform: translateY(-100%);
}

.boton-reproduccion {
  height: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #c62828;
  border-radius: 50%;
  font-size: 3rem;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.boton-reproduccion:hover {
  transform: scale(1.1);
}

.icono-play {
  width: 5rem; /* Establece el ancho fijo deseado para la icona */
  height: auto; /* Permite que la altura se ajuste automáticamente */
  font-size: 5rem; /* Mantiene el tamaño de la icona en 3rem */
}

.icono-play:hover {
  animation: saltar 1s linear infinite;
  cursor: pointer;
}

.icono-download {
  width: 3rem; /* Establece el ancho fijo deseado para la icona */
  height: auto; /* Permite que la altura se ajuste automáticamente */
  font-size: 3rem; /* Mantiene el tamaño de la icona en 3rem */
}

.icono-download:hover {
  animation: saltar 1s linear infinite;
  cursor: pointer;
}

.leyenda {
  color: black;
  font-size: 50%;
  margin-top: 0.5rem;
  text-align: center;
}

@keyframes saltar {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.iframe-container-vimeo {
  padding: 56.25% 0 0 0;
  position: relative;
}

.iframe-vimeo {
  position: absolute;
  top: 0;
  width: 75%;
  height: 75%;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 2);
  background-color: black;
}

@media (max-width: 767px) {
  /* Tamaño de pantalla correspondiente a dispositivos móviles */
  .iframe-vimeo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    background-color: black;
  }
}

.text-informative {
  font-size: 0.7em;
}

.transperente {
  opacity: 0.5;
}

/* Estilos para la galería de fotos */
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.photo-container {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer; /* Añadir cursor de pointer */
}

.photo-container:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.gallery-photo {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 8px;
}

.photo-description {
  margin-top: 10px;
  font-size: 1rem;
  color: #6c757d;
}

.modal img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/* Estilos para el modal */
.custom-modal .modal-content {
  background-color: rgba(255, 255, 255, 0); /* Fondo blanco con transparencia */
  border: none; /* Sin borde */
  border-radius: 10px; /* Esquinas redondeadas */
  max-width: 95vw; /* Aumentar el ancho máximo del modal */
  max-height: 95vh; /* Aumentar la altura máxima del modal */
}

.custom-modal .modal-header {
  border: none; /* Sin borde en el header */
}

.custom-modal .modal-body {
  padding: 0; /* Sin padding para ajustar la imagen */
}

.custom-modal .modal-title {
  font-size: 2rem; /* Tamaño de fuente más grande para el título */
}

.custom-modal .modal img {
  width: 100%; /* Ajusta el ancho de la imagen al 100% */
  height: auto; /* Mantiene la relación de aspecto */
  max-height: 80vh; /* Altura máxima para no exceder la ventana */
  object-fit: contain; /* Mantiene la relación de aspecto */
}

.image-container {
  position: relative; /* Necesario para la posición del texto */
}

.image-description {
  position: absolute;
  bottom: 0; /* Puedes ajustar esto según tu diseño */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente */
  color: white; /* Color del texto */
  padding: 10px; /* Espaciado */
  opacity: 0; /* Comienza oculto */
  transition: opacity 0.3s ease; /* Transición suave */
  text-align: center;
}

.image-container:hover .image-description {
  opacity: 1; /* Muestra la descripción al pasar el mouse */
}

.custom-modal {
  max-width: 90%; /* Ajusta el ancho máximo del modal */
  max-height: 90%; /* Ajusta la altura máxima del modal */
  margin: auto; /* Centra el modal */
}

.image-container {
  position: relative; /* Necesario para la posición del texto */
  display: flex; /* Asegúrate de que se ajuste al tamaño de la imagen */
  justify-content: center; /* Centra la imagen horizontalmente */
  align-items: center; /* Centra la imagen verticalmente */
}

.image-description {
  position: absolute;
  bottom: 0; /* Coloca el texto en la parte inferior de la imagen */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Fondo semi-transparente */
  color: white; /* Color del texto */
  padding: 10px; /* Espaciado */
  opacity: 0; /* Comienza oculto */
  transition: opacity 0.3s ease; /* Transición suave */
  text-align: center;
}

.image-container:hover .image-description {
  opacity: 1; /* Muestra la descripción al pasar el mouse */
}
