@import url("https://fonts.googleapis.com/css?family=Arvo&display=swap");

.SVNavbar {
  background-color: #d3d3d3;
}

.App {
  background-color: #ebeaea;
  min-height: 100vh;
  height: 100%;
}

.custom-row {
  align-self: flex-end;
}

.page-container {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SvTitulo {
  text-align: center;
}

.SvFilterTemporada {
  float: right;
}

.imagenRedondaX {
  border-radius: 50%;
}

.socialMedia {
  font-size: 2rem;
  color: black;
  padding-right: 1rem;
}

.iconIsActivated {
  font-size: 1rem;
  color: darkgreen;
  padding-right: 1rem;
}

.iconIsNotActivated {
  font-size: 1rem;
  color: darkred;
  padding-right: 1rem;
}

.textarea-size-short {
  height: 100px;
}

.textarea-size-large {
  height: 200px;
}

body {
  font-family: "Arvo", serif !important;
}
